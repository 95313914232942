













import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataItem } from "@/views/chat/journal/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import TableItem from "@/views/chat/journal/table/table-item.vue";

import { UseFields } from "piramis-base-components/src/components/Pi/index";

@Component({
  components: {
    TableItem,
  }
})
export default class JournalTable extends Mixins(UseFields, TariffsTagsHelper) {
  @Prop({ required: true }) logs!: Array<DataItem>
  @Prop({ required: true }) visible!: number

  titles: Array<string> = [ /*"uid",*/"user", "event_type", "reason_type", "punish_type", "time" ]

  getWidth(title: string): string {
    switch (title) {
      case 'user':
        return 'w-2/12'
      case 'event_type':
        return 'w-2/12'
      case 'reason_type':
        return 'w-5/12'
      case 'punish_type':
        return 'w-3/12'
      case 'time':
        return 'w-2/12'
      default:
        return ''
    }
  }

}
