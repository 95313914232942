
























import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
@Component({
  components: { UserMainInfo }
})
export default class JournalRecordUserInfo extends Vue {
  @Prop() sectionTitle!:string

  @Prop() userAvatar!:string

  @Prop() userName!:string

  @Prop() userLogin!:string

  @Prop() userId!:number
}
