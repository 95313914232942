







































































































import camelToUnderscoreCase from "@/assets/utils/camelToUnderscoreCase"
import Api from "../../../includes/logic/Api"
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue"
import PageTitle from '@/components/PageTitle.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper"
import TariffsTagsHelper from "../../../mixins/TariffsTagsHelper"
import JournalTable from './table/journal-table.vue'
import { DataItem } from './types'
import { errorNotification, successNotification } from '@/includes/NotificationService'

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from "piramis-base-components/src/components/Pi/types";

import { ExportToCsv } from 'export-to-csv'
import { snakeCase, debounce } from "lodash";
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { AxiosPromise } from "axios"
import moment from "moment";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    PageTitle,
    CenteredColumnLayout,
    JournalTable,
    Accordion,
    TableExportButtons
  },
  methods: {
    getTariffTranslationKey,
  },
  data() {
    return {
      moment,
      snakeCase,
      debounce,
      camelToUnderscoreCase
    }
  }
})
export default class JournalLog extends Mixins(UseFields, TariffsTagsHelper) {
  log: Array<DataItem> = []

  showArrest: boolean = false

  arrest: boolean = false

  tableTemplate: number = 0

  searchProgress: boolean = false

  loadedLogs: number = this.log.length

  searchLog: Array<DataItem> = []

  search: string = ''

  logsRunOut: boolean = false

  visibleLogs: number = 1

  loading: boolean = false

  showButton: boolean = true

  titles: Array<string> = [ /*"uid",*/"user", "event_type", "reason_type", "punish_type", "time" ]

  userInfoNoChange: Array<string> = [ 'actor_user_avatar', 'target_user_avatar', 'actor_user_login', 'target_user_login' ]

  userInfoKeys: Array<string> = [ "actor_user_avatar", "target_user_avatar", "actor_user_name", "actor_user_login", "target_user_id", "actor_user_id", "target_user_id", "target_user_name", "target_user_login" ]

  filters: { [key: string]: boolean } = {
    FacecontrolRemoveBot: false,
    FacecontrolRemoveBotUser: false,
    EnterNewMember: false,
    ReturnNewMember: false,
    CommandUnwarn: false,
    CommandReslim: false,
    CommandReport: false,
    CommandOn: false,
    CommandOff: false,
    CommandMute: false,
    CommandKick: false,
    CommandBan: false,
    CommandUnban: false,
    CommandUnmute: false,
    CommandWarn: false,
    CommandRooff: false,
    CommandRo: false,
    CommandDellog: false,
    CommandRoall: false,
    CommandRepin: false,
    CommandUnrepin: false,
    RoMode: false,
    CommandBotaddadmin: false,
    CommandBotdeladmin: false,
    CommandSiteaddadmin: false,
    CommandSitedeladmin: false,
    FacecontrolRtl: false,
    FacecontrolMinLen: false,
    FacecontrolMaxLen: false,
    GuardEditedMessage: false,
    GuardNewLimit: false,
    CaptchaNotVerifiedKick: false,
    CommandKickAll: false,
    CommandKickAllNew: false,
    CommandKickAllOff: false,
    KickAllMode: false,
    CommandTestMode: false,
    CommandTestModeOff: false,
    TriggerCall: false,
    TriggerMessageDelete: false,
    TriggerDisabled: false,
    TriggerMute: false,
    TriggerKick: false,
    TriggerBan: false,
    TriggerUnban: false,
    TriggerUnmute: false,
    TriggerWarn: false,
    TriggerTriggerWarn: false,
    CommandUn: false,
    FacecontrolHieroglyph: false,
    FacecontrolLangCode: false,
    FacecontrolLoginMinLen: false,
    FacecontrolLoginMaxLen: false,
    FacecontrolName: false,
    FacecontrolBindedChannel: false,
    UpdateSettings: false,
    ReportAction: false,
    UpdateTrigger: false,
    UpdateActiveTriggers: false,
    RemoveUserByMathCaptcha: false,
    UserCompleteCaptcha: false,
    UserReEnterBan: false,
    KickSecondInactive: false,
    KickInactive: false,
    MemberLeave: false,
    MemberKicked: false,
    SendMsgToChat: false,
    ExecuteApiCommand: false,
    MaxAlbumItems: false,
    UserbotDetected: false,
    LogActionMute: false,
    LogActionKick: false,
    LogActionBan: false,
    LogActionUnban: false,
    LogActionUnmute: false,
    LogAdminActionResetChatLimits: false,
    LogAdminActionResetChatRep: false,
    LogAdminActionResetChatAp: false,
    LogAdminActionResetChatXp: false,
    LogAdminActionResetChatRanks: false,
    LogAdminActionResetChatConfig: false,
    LogAdminActionAddChatAdmins: false,
    LogAdminActionRestoreChatConfig: false,
    CommandRepreset: false,
    AutoAttackModeOn: false,
    AutoAttackModeOff: false,
    NsfwDetected: false,
    UserPropTransition: false,
    BrandBotApiError: false,
    CloseChatModeEnabled: false,
    CloseChatModeDisabled: false,
    ApiNotifyDisabled: false,
    SendPostError: false,
    ProcessPostActionError: false,
    FilterNewLimitMsgCount: false,
    FilterWarning: false,
    FilterPunish: false,
    FilterRemoveEditedByMessageDiff: false,
    FilterRemoveEditedByTimeout: false,
    FilterRemoveEdited: false,
    FilterRemoveEditedNone: false,
  }

  filtersSettings = false

  activeFilters: Array<string> = []

  @Watch('activeFilters')
  activeFiltersWatcher(active: Array<string>) {
    if (active.length || this.search.trim().length) {
      this.verified = 0
      this.searchLog = []
      this.tableTemplate += 1
      this.goSearch()
    } else {
      this.verified = 0
      this.searchLog = []
      this.searchProgress = false
      this.tableTemplate += 1
    }
  }

  @Watch('filtersSettings')
  sidebarWatcher(value: boolean) {
    if (!value && this.activeFilters.length) {
      this.goSearch()
    }
  }

  // @Watch('loading')
  // loadingWatcher(bool: boolean) {
  //   if (bool) {
  //     this.$vs.loading({
  //       background: "primary",
  //       color: "#fff",
  //       container: "#loading-journal-button",
  //       scale: 0.3,
  //     })
  //   } else {
  //     this.$vs.loading.close('#loading-journal-button > .con-vs-loading');
  //   }
  // }

  @Watch('filters', { deep: true })
  filtersWatcher(obj: { [key: string]: boolean }) {
    Object.entries(obj).forEach(([ filter, value ]) => {
      const filterActive = this.activeFilters.includes(filter)
      const filterIndex = this.activeFilters.indexOf(filter)

      if (value && !filterActive) {
        this.activeFilters.push(filter)
      }
      if (!value && filterActive) {
        this.activeFilters.splice(filterIndex, 1)
      }
    })
    this.tableTemplate += 1
  }

  @Watch('search')
  searchWatcher(search: string): void {
    this.searchLog = []
    if (!search.trim().length && !this.activeFilters.length) {
      this.visibleLogs = 1
      this.searchProgress = false
    }
    this.tableTemplate += 1
    this.verified = 0
  }

  getFilters(): Array<{label: string, value: string}> {
    let filters: Array<{label: string, value: string}> = []
    Object.keys(this.filters).forEach(key => {
      filters.push({
        label: this.$t(`journal_log_${ camelToUnderscoreCase(key) }`).toString(),
        value: key
      })
    })
    return filters
  }

  inputSetup(arg: FieldData): FieldData {
    arg.setter = (value) => {
      this.activeFilters = value
    }
    return arg
  }

  resumeSearch() {
    this.arrest = false
    this.goSearch()
  }

  configInputSetup(arg: FieldData): FieldData {
    arg.setter = (value) => {
      this.activeFilters = value
    }
    return arg
  }

  arrested() {
    this.showArrest = false
    this.arrest = true
  }

  searching() {
    if (this.$route.name?.toLowerCase() === 'journal_log') {
      if ((this.search.trim() || this.activeFilters.length) && this.searchLog.length < 20 * this.visibleLogs && !this.arrest) {
        if (!this.loading) {
          this.loading = true
        }

        this.parsingData()

        if (this.searchLog.length < 20 * this.visibleLogs && !this.logsRunOut && !this.arrest) {
          setTimeout(() => {
            this.loadMore().then(() => {
              this.parsingData()
              this.searching()
              this.verified += 100
            })
          }, 800)
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  }

  loadTable(): void {
    this.$confirm({
      title: this.$t('attention').toString(),
      content: this.$t(`journal_log_attention_alert_to_download`, { 0: this.log.length }).toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk:() => {
        this.getTable()
      }
    })
  }

  getTable(): void {
    const options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': `Journal of ${ this.$store.state.chatState.chat.group_title }`,
      'title': `Journal of ${ this.$store.state.chatState.chat.group_title }`,
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    const data = this.log.map(item => {
      let dataItem: DataItem = {}
      Object.entries(item).forEach(([ field, value ]) => {
        dataItem[this.$t(`journal_log_${ field }`).toString()] = value
      })
      return dataItem
    })

    new ExportToCsv(options).generateCsv(data)
  }

  verified: number = 0

  parsingData(): void {
    let array = this.verified === 0 ? this.log : this.log.slice(this.verified, this.verified + 100)

    array.forEach(srItem => {
      let log: DataItem = { ...srItem }
      const searchTerm = this.search.trim().toLowerCase()
      Object.entries(log).forEach(entry => {
        if (this.search.trim()) {
          const key = entry[0]
          let value = entry[1]
          if (typeof value === 'string') {
            if (value.toLowerCase().includes(searchTerm)) {
              let searchPosition = value.toLowerCase().indexOf(searchTerm)
              let search = value.slice(searchPosition, searchPosition + this.search.trim().length)

              log[key] = this.userInfoNoChange.includes(key) ? log[key] : value.replace(search, `<mark>${ search }</mark>`)
            }
          }
        }
      })

      const addLog = () => {
        this.searchLog.find(item => item.update_id === log.update_id) ? null : this.searchLog.push(log)
      }
      const checkMatches = () => {
        return Object.values(log).some(value => String(value).toLowerCase().includes(searchTerm))
      }

      if (this.activeFilters.length && !this.search.trim()) {
        if (this.activeFilters.includes(log.reason_type as string)) {
          addLog()
        }
      }
      if (this.search.trim() && !this.activeFilters.length && checkMatches()) {
        addLog()
      }
      if (this.search.trim() && this.activeFilters.length && checkMatches()) {
        if (this.activeFilters.includes(log.reason_type as string)) {
          addLog()
        }
      }
    })
  }

  removeFilter(filter: string): void {
    this.filters[filter] = false
    this.$nextTick(() => {
      if (!this.activeFilters.length && !this.search.trim().length) {
        this.searchProgress = false
      }
    })
  }

  load(): void {
    if (this.arrest) {
      this.arrest = false
      this.goSearch()
    } else {
      if (this.search.trim() || this.activeFilters.length) {
        this.visibleLogs += 1
        this.searching()
      } else {
        if (this.log.length > this.visibleLogs * 20) {
          this.visibleLogs += 1
        } else {
          this.loadMore().then(() => {
            this.visibleLogs += 1
          })
        }
      }
    }
  }

  async loadMore(): Promise<void> {
    try {
      const { data } = await this.getJournal(this.$store.state.chatState.chat.chat_id, this.log.length, 100)
      this.updateData(data.items)
    } catch (error) {
      errorNotification(error)
    }
  }

  updateData(items: Array<DataItem>): void {
    this.log.push(...items);
    items.length === 0 && (this.showButton = false)
    items.length === 0 ? this.logsRunOut = true : null
  }

  getJournal(chat_id: number, offset: number = 0, limit: number = 2000): AxiosPromise {
    return Api.getJournal("tg", {
      chat_id,
      offset,
      limit,
    });
  }

  goSearch(): void {
    if (!this.loading && (this.search.trim().length || this.activeFilters.length)) {
      this.searchProgress = true
      this.tableTemplate += 1
      this.verified = 0
      this.visibleLogs = 1
      this.searchLog = []
      this.searching()
    }
  }

  loadMoreButton(): void {
    if (this.loading) {
      this.arrested()
    } else {
      if (this.search.trim().length || this.activeFilters.length) {
        if (this.searchLog.length < 20 * this.visibleLogs) {
          this.resumeSearch()
        } else {
          this.load()
        }
      } else {
        this.load()
      }
    }
  }

  destroy() {
    this.searchProgress = false
  }

  async mounted(): Promise<void> {
    if (!this.getTagsByFieldKey(`site_journal`)) {

      let context = this
      let searchInput: HTMLElement = document.getElementById('search-input') as HTMLElement

      searchInput.onkeyup = debounce(() => {
        context.arrest ? context.arrest = false : null
        this.goSearch()
      }, 400)
      if (!this.getTagsByFieldKey(`site_journal`)) {
        this.$baseTemplate.loader.open();
        try {
          const { data } = await this.getJournal(this.$store.state.chatState.chat.chat_id, this.log.length, 100)
          this.log = data.items
        } catch (error) {
          errorNotification(error)
        } finally {
          this.$baseTemplate.loader.close();
        }
      }
    }
  }
}
