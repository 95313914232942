












































































































import { DataItem } from "@/views/chat/journal/types";
import isMobile from "@/assets/utils/isMobile";
import camelToUnderscoreCase from "@/assets/utils/camelToUnderscoreCase"
import JournalRecordUserInfo from './JournalRecordUserInfo.vue'
import JournalRecordMessage from "./JournalRecordMessage.vue";

import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { snakeCase } from "lodash";
import moment from 'moment'

@Component({
  components: {
    JournalRecordUserInfo,
    JournalRecordMessage
  },
  data() {
    return {
      isMobile,
      snakeCase,
      camelToUnderscoreCase,
      moment
    }
  }
})
export default class TableItem extends Vue {
  @Prop({ required: true }) item!: DataItem

  actor: { message: string, id: number, avatar: string, name: string, login: string } = {
    avatar: this.item.actor_user_avatar as string,
    id: this.item.actor_user_id as number,
    login: this.item.actor_user_login as string,
    message: this.replaceNewLine(this.item.actor_message as string),
    name: this.item.actor_user_name as string
  }

  target: { message: string, id: number, avatar: string, name: string, login: string } = {
    avatar: this.item.target_user_avatar as string,
    id: this.item.target_user_id as number,
    login: this.item.target_user_login as string,
    message: this.replaceNewLine(this.item.target_message as string),
    name: this.item.target_user_name as string
  }

  get hasExtra():boolean {
    return !!this.target.message.length || !!this.actor.message.length || !!(this.item.description as any).length
  }
  check(original: string, masked: string, originalIndex: number, maskedIndex: number, oms: string): boolean {
    while (originalIndex >= 0 || maskedIndex >= 0) {
      let os = original[originalIndex]
      let ms = masked[maskedIndex]
      if (ms === oms) {
        break
      }
      if (os !== ms) {
        return false
      }
      originalIndex--
      maskedIndex--
    }
    return true
  }

  getDescription(): string {
    const target = this.target.name ? this.target.name : this.target.login
    const actor = this.actor.name ? this.actor.name : this.actor.login
    const targetLogin = this.target.login ? this.target.login : this.target.id
    const actorLogin = this.actor.login ? this.actor.login : this.target.id
    if (this.item.filter_type === 'Warn' && !(this.item.description as string).length) {
      return this.$t('filter_warn_title', {
        actor_name: actor,
        target_name: target,
        actor_login: actorLogin,
        target_login: targetLogin
      }).toString()
    } else {
      return this.replaceNewLine(this.item.description as string)
    }
  }

  replaceNewLine(html: string): string {
    return html.replace(/\\n/g, "<br/>");
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed() {
    this.$baseTemplate.saveButton.show();
  }
}
